import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AuthState } from '@aws-amplify/ui-components'
import { API, Auth } from "aws-amplify";
// import { CognitoUser } from "@aws-amplify/auth";
// import { CognitoUserSession } from "amazon-cognito-identity-js";

export interface AccountState {
  user: null|any;
  // authState: AuthState | null;
  balance: number;
  balanceStatus: boolean;
}
@Module({
  name: "Account",
  namespaced: true,
  stateFactory: true
})
export default class Account extends VuexModule implements AccountState {
  user = null as any;
  // authState=
  balance = 100000;
  balanceStatus = false;

  @Mutation
  CHANGE_AUTH_STATE(state: AuthState) {
    // this.authState = state as AuthState;
    // console.log("CHANGE_AUTH_STATE");
    // console.log(state);
  }

  @Mutation
  CHANGE_USER(user: any) {
    this.user = user;
  }

  @Mutation
  CHANGE_BALANCE_STATUS(status: boolean) {
    this.balanceStatus = status;
  }

  @Mutation
  SET_BALANCE(balance: number) {
    this.balance = balance;
  }

  @Mutation
  ADD_TO_BALANCE(credit: number) {
    this.balance += credit;
  }

  @Mutation
  SUBTRACT_FROM_BALANCE(debit: number) {
    this.balance -= debit;
  }

  @Action
  async getBalance() {
    if (!this.balanceStatus) {
      this.CHANGE_BALANCE_STATUS(true);
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      };
      API.get("roodgeelblauw-api", "/api/v1/balance", myInit)
        .then(response => {
          console.log("success");
          console.log(response);
          this.SET_BALANCE(response.data);
          this.CHANGE_BALANCE_STATUS(false);
        })
        .catch(error => {
          console.log(error);
          this.CHANGE_BALANCE_STATUS(false);
        });
    }
  }

  @Action
  async signOut() {
    Auth.signOut()
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log("error signing out: ", error);
      });
  }

  @Action
  async signOutAll() {
    Auth.signOut({ global: true })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log("error signing out: ", error);
      });
  }

  @Action
  buyTelesur(amount_in_cents: number) {
    const commission = 0.06;
    if (this.balance >= amount_in_cents * ( 1 - commission) ) {
      this.SUBTRACT_FROM_BALANCE(amount_in_cents * ( 1 - commission));
    }
  }

  @Action
  buyDigicel(amount_in_cents: number) {
    const commission = 0.05;
    if (this.balance >= amount_in_cents * ( 1 - commission) ) {
      this.SUBTRACT_FROM_BALANCE(amount_in_cents * ( 1 - commission));
    }
    //TODO Perform on server
  }
  //Send request
  //Fetch requests
}
