














































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  required,
  digits,
  regex,
  min_value,
  max_value
} from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";
import { Transaction } from "@/models/transaction";
import QRDialog from "@/components/QRDialog.vue";
import QRCodeScanDialog from "@/components/QRCodeScanDialog.vue";
@Component({
  components: {
    QRCodeScanDialog,
    QRDialog,
    ValidationObserver,
    ValidationProvider
  }
})
export default class TransactionForm extends Vue {
  @Prop({ default: 'endUser' }) readonly client_type!: string

  algorandaddress = 'EWDECG7OS4SU2O65TQTTK5QJOW3LT52DRFIOVD4CKXP43UCAIDOFTMDQMA';
  amount = "";
  phonenumber = "";
  // ^(71|72|74|76|81|82|84|85|86|87|88|89)\\d{5}$
  paymentmethod = "";
  paymentmethods = ['algorand', 'store' ];
  mounted() {
    this.clear();
    setInteractionMode("eager");
    extend("required", {
      ...required,
      message: "{_field_} can not be empty. ({_value_})"
    });
    extend("digits", {
      ...digits,
      message: "{_field_} needs to be {length} digits. ({_value_})"
    });
    extend("min_value", {
      ...min_value,
      message: "{_field_} needs to be at least {min}. ({_value_})"
    });
    extend("max_value", {
      ...max_value,
      message: "{_field_} needs to be less than {max}. ({_value_})"
    });
    extend("regex", {
      ...regex,
      message: "{_field_} does not match {regex}. ({_value_})"
    });
  }
  clear() {
    this.phonenumber = "";
    this.amount = "";
    this.paymentmethod = "store";
    (this.$refs.observer as Vue & { reset: () => boolean }).reset();
  }

  submit() {
    const transaction: Transaction = new Transaction(
      null as any,
      new Date().toISOString(),
      Number(597 + this.phonenumber),
      Number(this.amount) * 100,
      "sending",
      ""
    );
    if(this.client_type === "endUser"){
      //make a QR Code
      this.clear();
    } else if(this.client_type === "client"){
      this.$modules.topup.addTransaction(transaction);
      //add Notification that it has been sent
      this.$modules.notification.addNotification(
        transaction.toString()
      );
    }

    // this.$getLocation(options)
    //   .then(coordinates => {
    //     console.log(coordinates);
    //   });
  }

  onUpdatePhonenumber(phonenumber: string) {
    this.phonenumber = phonenumber;
  }

  onUpdateAmount(amount: string) {
    this.amount = amount;
  }

  onUpdatePaymentMethod(paymentmethod: string) {
    this.paymentmethod = paymentmethod;
  }

  get isEndUser() {
    return this.client_type === "endUser";
  }

  get isClient() {
    return this.client_type === "client";
  }

  qrcode() {
    if (this.paymentmethod === "store") {
      return `{phonenumber: ${this.phonenumber}, amount: ${this.amount}}`;
    } else if (this.paymentmethod === "algorand") {
      const amountInAlgorand: number = Math.floor(Number(this.amount) * 1000000 / 21 +1);
      return `algorand://${this.algorandaddress}?label=roodgeelblauw&amount=${amountInAlgorand}&xnote=${this.phonenumber}`;
    }
  }
}
