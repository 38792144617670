









































  import {Component, Emit, Prop, PropSync, Vue} from "vue-property-decorator";
  import StreamBarCodeReader from "@/components/StreamBarCodeReader.vue";
  @Component({
    components: {StreamBarCodeReader}
  })
export default class QRCodeScanDialog extends Vue {
  @Prop({ default: 'Scan QR' }) readonly title!: string
  @PropSync('phonenumber', { type: String }) syncedPhonenumber!: string
  @PropSync('amount', { type: String }) syncedAmount!: string
  dialog = false;

  onDecode(decodedString: string) {
    const qrRegex = /^{phonenumber: ((71|72|74|76|81|82|84|85|86|87|88|89)\d{5}), amount: (\d{1,3})}$/;
    if(qrRegex.test(decodedString)) {
      const data = decodedString.match(qrRegex)!;
      this.syncedPhonenumber = data[1];
      this.syncedAmount = data[3];
      this.dialog = false;
    }
  }
}
