import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { API, Auth } from "aws-amplify";

export interface ClientState {
  listOfClients: string[];
}
@Module({
  name: "Client",
  namespaced: true,
  stateFactory: true
})
export default class Client extends VuexModule implements ClientState {
  listOfClients: string[] = [];

  @Mutation
  SET_CLIENTS(listOfClients: string[]) {
    this.listOfClients = listOfClients;
  }

  // @Action
  // async approveTransaction() {
  // //  Check if Real money was added
  // }

  @Action
  async getClients() {
    Auth.currentSession()
      .then(session => {
        const myInit = {
          headers: {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`
          }
        };
        API.get("roodgeelblauw-api", `/api/v1/accounts/`, myInit)
          .then(response => {
            console.log("Succesfully sent a GET: " + response);
            const listOfClients: string[] = [];
            for (let i = 0; i < response.length; i++) {
              if (response[i] !== undefined) {
                const temp = response[i];
                listOfClients[i] = temp.name;
              }
            }
            console.log(response);
            this.SET_CLIENTS(listOfClients);
          })
          .catch(error => {
            console.log("Could not POST: " + error);
          });
      })
      .catch(error => {
        console.log("Could not Auth: " + error);
      });
  }

  @Action
  async getClient(clientName: string) {
    Auth.currentSession()
      .then(session => {
        const myInit = {
          headers: {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`
          }
        };
        API.get("roodgeelblauw-api", `/api/v1/accounts/${clientName}`, myInit)
          .then(response => {
            console.log("Succesfully sent a GET: " + response);
            // const listOfTenants: Tenant[] = [];
            // for (let i = 0; i < response.length; i++) {
            //   if (response[i] !== undefined) {
            //     const temp = response[i];
            //     listOfTransactions[i] = new Transaction(
            //       temp.id,
            //       temp.request_time_iso_date,
            //       temp.phone_number,
            //       temp.amount_in_cents,
            //       "queue",
            //       "unknown"
            //     );
            //   }
            // }
            console.log(response);
            // this.SET_BALANCE(response.data);
            // this.CHANGE_BALANCE_STATUS(false);
            // this.SET_TRANSACTIONS(listOfTransactions);
            // this.SET_TRANSACTIONS_LOADING(false);
          })
          .catch(error => {
            console.log("Could not POST: " + error);
            // this.CHANGE_BALANCE_STATUS(false);
          });
      })
      .catch(error => {
        console.log("Could not Auth: " + error);
      });
  }

  @Action
  async addClient(companyName: string) {
    Auth.currentSession()
      .then(session => {
        const myInit = {
          headers: {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`
          },
          body: {
            // eslint-disable-next-line
            account: {
              // eslint-disable-next-line
              name: companyName
            }
          }
        };
        API.post("roodgeelblauw-api", "/api/v1/accounts", myInit)
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log("Could not Auth: " + error);
      });
  }

  @Action
  async addTenant(companyName: string, tenant: {firstName: string, lastName: string, email: string, phoneNumber:string, password: string}) {
    Auth.currentSession()
      .then(session => {
        const myInit = {
          headers: {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`
          },
          body: {
            tenant: {
              first_name: tenant.firstName,
              last_name: tenant.lastName,
              email: tenant.email,
              phone_number: tenant.phoneNumber,
              password: tenant.password,
            }
          }
        };
        API.post("roodgeelblauw-api", `/api/v1/accounts/${companyName}/tenants`, myInit)
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log("Could not Auth: " + error);
      });
  }
}
