import DateTimeFormatOptions = Intl.DateTimeFormatOptions;

export class Transaction {
  id: number
  isoDateOfRequest: string
  public phoneNumber: number
  amountInCents: number
  status: string
  referenceNumber: string

  constructor(
    id: number,
    isoDateOfRequest: string,
    phoneNumber: number,
    amountInCents: number,
    status: string,
    referenceNumber: string
  ) {
    this.id = id;
    this.isoDateOfRequest = isoDateOfRequest;
    this.phoneNumber = phoneNumber;
    this.amountInCents = amountInCents;
    this.status = status;
    this.referenceNumber = referenceNumber;
  }

  public get isDigicel() {
    const digicelRegex = /^597(71|72|74|76|81|82)\d{5}$/;
    return digicelRegex.test(this.phoneNumber.toString());
  }
  public get isTelesur() {
    const telesurRegex = /^597(84|85|86|87|88|89)\d{5}$/;
    return telesurRegex.test(this.phoneNumber.toString());
  }

  public get provider() {
    if (this.isTelesur) {
      return "Telesur";
    }
    if (this.isDigicel) {
      return "Digicel";
    }
    return "Unknown";
  }

  public get formattedAmount() {
    return (this.amountInCents / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "SRD",
      minimumFractionDigits: 2
    });
  }

  public get formattedPhoneNumber() {
    return (
      this.phoneNumber.toString().substr(3, 3) +
      " " +
      this.phoneNumber.toString().substr(6, 4)
    );
  }

  public get datetimeLocal() {
    const options: DateTimeFormatOptions = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
      timeZone: "America/Paramaribo",
      timeZoneName: "short"
    };
    const formatter = new Intl.DateTimeFormat("nl-SR", options);
    return formatter.format(new Date(this.isoDateOfRequest));
  }

  public get epoch() {
    return new Date(this.isoDateOfRequest).getTime();
  }

  public toString() {
    return "Sent request for +" +
      this.phoneNumber +
      " SRD" +
      this.amountInCents / 100 +
      " to " +
      this.provider
  }
}
