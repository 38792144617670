


















import { Component, Emit, Vue } from "vue-property-decorator";
import {
  BrowserQRCodeReader,
  ChecksumException,
  FormatException,
  NotFoundException
} from "@zxing/library";

@Component
export default class StreamBarCodeReader extends Vue {
  // codeReader = new BrowserMultiFormatReader();
  codeReader = new BrowserQRCodeReader();
  videoInputDevices: MediaDeviceInfo[] = [];
  videoInputDeviceIndex = 0;
  errors = "";
  $refs!: {
    scanner: HTMLVideoElement;
  };

  mounted() {
    this.setVideoInputDevices();
  }

  beforeDestroy() {
    this.codeReader.reset();
  }

  async setVideoInputDevices() {
    await this.codeReader
      .listVideoInputDevices()
      .then(response => {
        if (response.length > 0) {
          this.videoInputDevices = response;
          this.videoInputDeviceIndex = response.length - 1;
          this.start();
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  //
  // decodeCallBack(result: Result, error: Exception | undefined): void {
  //   if (result) {
  //     this.emitCode(result.getText());
  //     // console.log(result.getBarcodeFormat().toString());
  //   } else {
  //     if (error instanceof NotFoundException) {
  //       this.emitStatus("No QR code found.");
  //     } else if (error instanceof ChecksumException) {
  //       this.emitStatus("A code was found, but it's read value was not valid.");
  //     } else if (error instanceof FormatException) {
  //       this.emitStatus("A code was found, but it was in a invalid format.");
  //     } else {
  //       this.emitStatus("Undefined");
  //     }
  //   }
  // }

  @Emit("decode")
  emitCode(decodedString: string) {
    return decodedString;
  }

  @Emit("statusChange")
  emitStatus(status: string) {
    return status;
  }

  @Emit()
  start() {
    this.codeReader
      .decodeFromInputVideoDevice(
        this.videoInputDevices[this.videoInputDeviceIndex].deviceId,
        "video"
      )
      .then(result => {
        this.emitCode(result.getText());
        this.codeReader.reset();
      })
      .catch(error => {
        if (error instanceof NotFoundException) {
          this.emitStatus("No QR code found.");
        } else if (error instanceof ChecksumException) {
          this.emitStatus(
            "A code was found, but it's read value was not valid."
          );
        } else if (error instanceof FormatException) {
          this.emitStatus("A code was found, but it was in a invalid format.");
        } else {
          this.emitStatus("Undefined");
        }
      });
  }

  @Emit()
  changeVideoInputDevice() {
    this.videoInputDeviceIndex =
      (this.videoInputDeviceIndex + 1) % this.videoInputDevices.length;
    this.start();
  }

  get hasMultipleInputDevice() {
    return this.videoInputDevices.length > 1;
  }
}
