import Vue from "vue";
import Vuex, { Store } from "vuex";
import Topup from "./modules/topup";
import Notification from "./modules/notification";
import Account from "./modules/account";
import Client from "./modules/client";

Vue.use(Vuex);

export default new Vuex.Store({
  // state: {},
  // mutations: {},
  // actions: {},
  modules: {
    Topup,
    Notification,
    Account,
    Client
  }
});
