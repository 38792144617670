















































import {Component, Prop, Vue} from "vue-property-decorator";
import QrcodeVue from "qrcode.vue";
@Component({
  components: {
    QrcodeVue
  }
})
export default class QRDialog extends Vue {
  @Prop({ default: 'Click Me' }) readonly title!: string
  @Prop({ default: '' }) readonly value!: string
  @Prop({ default: true }) readonly disabled!: boolean
  dialog = false;
}
