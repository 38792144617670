import { getModule } from "vuex-module-decorators";
import Vue from "vue";
import store from "@/store";
import Topup from "@/store/modules/topup";
import Notification from "@/store/modules/notification";
import Account from "@/store/modules/account";
import Client from "@/store/modules/client";

export interface Modules {
  topup: Topup;
  notification: Notification;
  account: Account;
  client: Client;
}

export default {
  install(_Vue: typeof Vue) {
    const modules: Modules = {
      topup: getModule(Topup, store),
      notification: getModule(Notification, store),
      account: getModule(Account, store),
      client: getModule(Client, store)
    };
    _Vue.prototype.$modules = modules;
  }
};
