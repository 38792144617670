











import { Component, Vue } from "vue-property-decorator";
@Component
export default class SignOut extends Vue {
  signOut() {
    this.$modules.account.signOut();
  }
  signIn() {
    this.$router.push('/client');
  }

  get signedIn(){
    // return this.$modules.account.authState === "signedin" && this.$modules.account.user;
    return this.$modules.account.user
  }
}
