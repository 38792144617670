var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('validation-provider',{attrs:{"name":"PhoneNumber","rules":{
        required: true,
        digits: 7,
        regex: '^(71|72|74|76|81|82|84|85|86|87|88|89)\\d{5}$'
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":7,"error-messages":errors,"clearable":"","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',[_vm._v("mdi-phone")]),_vm._v(" Tel")]},proxy:true}],null,true),model:{value:(_vm.phonenumber),callback:function ($$v) {_vm.phonenumber=$$v},expression:"phonenumber"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Amount","rules":{
        required: true,
        min_value: 5,
        max_value: 250
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"clearable":"","hint":"Bedrag tussen 5 en 250","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-icon',[_vm._v("mdi-cash")]),_vm._v(" Bedrag")]},proxy:true}],null,true),model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Paymentmethod","rules":{
        required: true
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.paymentmethods,"error-messages":errors,"clearable":"","hint":"How will you pay","required":""},model:{value:(_vm.paymentmethod),callback:function ($$v) {_vm.paymentmethod=$$v},expression:"paymentmethod"}})]}}],null,true)}),(_vm.isClient)?_c('div',[_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":invalid}},[_vm._v(" Topup ")]),_c('v-btn',{on:{"click":_vm.clear}},[_vm._v(" clear ")]),_c('q-r-code-scan-dialog',{on:{"update:phonenumber":_vm.onUpdatePhonenumber,"update:amount":_vm.onUpdateAmount,"update:paymentmethod":_vm.onUpdatePaymentMethod}})],1):(_vm.isEndUser)?_c('div',[_c('q-r-dialog',{staticClass:"mr-4",attrs:{"type":"submit","disabled":invalid || _vm.phonenumber.length == 0 || _vm.amount.length == 0,"title":"Generate","value":_vm.qrcode()}}),_c('v-btn',{on:{"click":_vm.clear}},[_vm._v(" clear ")])],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }