












import { Component, Vue } from "vue-property-decorator";
import {onAuthUIStateChange} from "@aws-amplify/ui-components";
// import UpdateApp from "@/components/UpdateApp.vue";

@Component({
  components: {
    // UpdateApp,
  }
})
export default class App extends Vue {
  created() {
    onAuthUIStateChange((authState, authData) => {
      this.$modules.account.CHANGE_AUTH_STATE(authState);
      this.$modules.account.CHANGE_USER(authData);
    });
  }
}
