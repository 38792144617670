import "@aws-amplify/ui-vue";
import { Amplify } from "aws-amplify";

export default Amplify.configure({
  // OPTIONAL - if your API requires authentication
  Auth: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_E9MtrjreZ",
    userPoolWebClientId: "5uqdf9kl681ootdlr8e7lor4og"
  },
  API: {
    endpoints: [
      {
        name: "roodgeelblauw-api",
        endpoint: "https://e7fab7qbp4.execute-api.eu-west-1.amazonaws.com/prod",
        // endpoint: "http://127.0.0.1",
        region: "eu-west-1"
        // custom_header: async () => {
        //   return Authorization: 'Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}'
        // }
      }
    ]
  }
});
