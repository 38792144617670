



















import { Component, Vue } from "vue-property-decorator";
import TransactionForm from "@/components/TransactionForm.vue";
import SignOut from "@/components/SignOut.vue";

@Component({
  components: {
    SignOut,
    TransactionForm
  }
})
export default class Home extends Vue {
}
