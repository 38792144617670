import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
// import { API, Auth } from "aws-amplify";

export interface NotificationState {
  notifications: string[];
  notificationStatus: boolean;
}
@Module({
  name: "Notification",
  namespaced: true,
  stateFactory: true
})
export default class Notification extends VuexModule
  implements NotificationState {
  notifications: string[] = [];
  notificationStatus = false;

  @Mutation
  CHANGE_NOTIFICATION_STATUS(status: boolean) {
    this.notificationStatus = status;
  }

  @Mutation
  ADD_NOTIFICATION(notification: string) {
    this.notifications.push(notification);
  }

  @Mutation
  CLEAR_NOTIFICATIONS() {
    this.notifications = [];
    this.notificationStatus = false;
    // const index = this.notifications.indexOf(notification);
    // if (index > -1) {
    //   this.notifications.splice(index, 1);
    //   this.notificationStatus = false;
    // }
  }

  @Action
  async removeNotification() {
    this.CLEAR_NOTIFICATIONS();
  }

  @Action
  async addNotification(notification: string) {
    this.CLEAR_NOTIFICATIONS();
    this.CHANGE_NOTIFICATION_STATUS(false);
    this.ADD_NOTIFICATION(notification);
    this.CHANGE_NOTIFICATION_STATUS(true);
  }
}
