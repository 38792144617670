/* eslint-disable no-console */

import { register } from "register-service-worker";

// const cacheName = 'v1';
// const cacheAssets = [
//   'index.html'
// ]
// if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // alert(
      //   "App is being served from cache by a service worker."
      // );
    },
    registered() {
      // alert("Service worker has been registered.");
    },
    cached() {
      // alert("Content has been cached for offline use.");
    },
    updatefound() {
      // alert("New content is downloading.");
      // alert("Update found. Going to update");
      // window.location.reload();
    },
    updated(registration) {
      // alert("New content is available; please refresh.");
      // alert("We have updated.");
      // document.dispatchEvent(
      //   new CustomEvent('swUpdated', { detail: registration })
      // )
    },
    offline() {
      alert(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      alert(`Error during service worker registration:  $error`);
    }
  });
// }
