import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Transaction } from "@/models/transaction";
import { API, Auth } from "aws-amplify";
import { PaginateOptions } from "@/models/paginate_options";

export interface TopupState {
  totalTransactions: number;
  transactions: Transaction[];
  transactionsLoading: boolean;
  locatable: boolean;
  location: Position;
  transactionSending: boolean;
  balanceTelesur: number;
  balanceTelesurStatus: boolean;
  balanceDigicel: number;
  balanceDigicelStatus: boolean;
}
// const sleep = (milliseconds: number) => {
//   return new Promise(resolve => setTimeout(resolve, milliseconds));
// };
@Module({
  name: "Topup",
  namespaced: true,
  stateFactory: true
})
export default class Topup extends VuexModule implements TopupState {
  totalTransactions = 0;
  transactions: Transaction[] = [];
  transactionsLoading = false;
  transactionSending = false;
  locatable = false;
  location = null as any;
  balanceTelesur = 100000;
  balanceTelesurStatus = false;
  balanceDigicel = 200000;
  balanceDigicelStatus = false;

  @Mutation
  SET_TOTAL_TRANSACTIONS(totalTransactions: number) {
    this.totalTransactions = totalTransactions;
  }

  @Mutation
  SET_TRANSACTIONS(listOfTransactions: Transaction[]) {
    this.transactions = listOfTransactions;
  }

  @Mutation
  ADD_TRANSACTION(transaction: Transaction) {
    this.transactions.push(transaction);
  }

  @Mutation
  SET_TRANSACTION_SENDING(status: boolean) {
    this.transactionSending = status;
  }

  @Mutation
  SET_TRANSACTIONS_LOADING(status: boolean) {
    this.transactionsLoading = status;
  }

  @Mutation
  CHANGE_LOCATION(position: Position) {
    this.location = position;
  }

  @Mutation
  CHANGE_TOPUP_BALANCE_STATUS(data: { provider: string; status: boolean }) {
    if (data.provider === "Telesur") {
      this.balanceTelesurStatus = data.status;
    } else if (data.provider === "Digicel") {
      this.balanceDigicelStatus = data.status;
    }
  }

  @Mutation
  SUBTRACT_FROM_TOPUP_BALANCE(data: { provider: string; amount: number }) {
    if (data.provider === "Telesur") {
      this.balanceTelesur -= data.amount;
    } else if (data.provider === "Digicel") {
      this.balanceDigicel -= data.amount;
    }
  }

  @Mutation
  SET_TOPUP_BALANCE(data: { provider: string; balance: number }) {
    if (data.provider === "Telesur") {
      this.balanceTelesur = data.balance;
    } else if (data.provider === "Digicel") {
      this.balanceDigicel = data.balance;
    }
  }

  @Action
  async getTransactions(options: PaginateOptions) {
    this.SET_TRANSACTIONS_LOADING(true);
    Auth.currentSession()
      .then(session => {
        // console.log("Options received");
        // console.log(options);
        const myInit = {
          headers: {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`
          },
          queryStringParameters: {
            "page[number]": options.page,
            "page[size]": options.itemsPerPage
            // "sort": options.jsonapiSort()
          }
        };
        // console.log(myInit);
        API.get("roodgeelblauw-api", "/api/v1/client_requests", myInit)
          .then(response => {
            console.log(response);
            const listOfTransactions: Transaction[] = [];
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i] !== undefined) {
                const temp = response.data[i];
                const transaction = new Transaction(
                  temp.id,
                  temp.attributes.request_time_iso_date,
                  temp.attributes.phone_number,
                  temp.attributes.amount_in_cents,
                  "queue",
                  "unknown"
                );
                listOfTransactions[i] = transaction;
              }
            }
            this.SET_TOTAL_TRANSACTIONS(response.meta.totalPages);
            this.SET_TRANSACTIONS(listOfTransactions);
          })
          .catch(error => {
            console.log("Could not POST: " + error);
            // this.CHANGE_BALANCE_STATUS(false);
          })
          .finally(() => {
            this.SET_TRANSACTIONS_LOADING(false);
          });
      })
      .catch(error => {
        console.log("Could not Auth: " + error);
      });
  }

  @Action
  async findTransactions(searchQuery: string) {
    console.log(`running search for ${searchQuery}`);
    // TODO implement search
  }

  @Action
  async addTransaction(transaction: Transaction) {
    this.ADD_TRANSACTION(transaction);
    this.SUBTRACT_FROM_TOPUP_BALANCE({
      provider: transaction.provider,
      amount: transaction.amountInCents
    });
    //Send data to server
    this.SET_TRANSACTION_SENDING(true);
    this.CHANGE_TOPUP_BALANCE_STATUS({
      provider: transaction.provider,
      status: true
    });
    Auth.currentSession()
      .then(session => {
        const myInit = {
          headers: {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`
          },
          body: {
            // eslint-disable-next-line
            client_request: {
              // eslint-disable-next-line
              amount_in_cents: transaction.amountInCents,
              // eslint-disable-next-line
              phone_number: transaction.phoneNumber,
              // eslint-disable-next-line
              request_time_iso_date: transaction.isoDateOfRequest,
              location: this.location
            }
          }
        };
        API.post("roodgeelblauw-api", "/api/v1/client_requests", myInit)
          .then(response => {
            console.log("Succesfully sent a POST: " + response.toString());
            this.SET_TRANSACTION_SENDING(false);
            this.CHANGE_TOPUP_BALANCE_STATUS({
              provider: transaction.provider,
              status: false
            });
            // this.SET_BALANCE(response.data);
            // this.CHANGE_BALANCE_STATUS(false);
          })
          .catch(error => {
            console.log("Could not POST: " + error);
            this.SET_TRANSACTION_SENDING(false);
            this.CHANGE_TOPUP_BALANCE_STATUS({
              provider: transaction.provider,
              status: false
            });
          });
      })
      .catch(error => {
        console.log("Could not Auth: " + error);
        this.SET_TRANSACTION_SENDING(false);
        this.CHANGE_TOPUP_BALANCE_STATUS({
          provider: transaction.provider,
          status: false
        });
      });
  }

  @Action
  async getBalance(provider: string) {
    this.CHANGE_TOPUP_BALANCE_STATUS({ provider: provider, status: true });
    this.SET_TRANSACTION_SENDING(true);
    Auth.currentSession()
      .then(session => {
        const myInit = {
          headers: {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`
          },
          body: {
            balance: {
              provider: provider
            }
          }
        };
        API.post("roodgeelblauw-api", "/api/v1/balance", myInit)
          .then(response => {
            console.log("Succesfully sent a POST: " + response);
            this.SET_TRANSACTION_SENDING(false);
            this.CHANGE_TOPUP_BALANCE_STATUS({
              provider: provider,
              status: false
            });
          })
          .catch(error => {
            console.log("Could not POST: " + error);
            this.SET_TRANSACTION_SENDING(false);
            this.CHANGE_TOPUP_BALANCE_STATUS({
              provider: provider,
              status: false
            });
          });
      })
      .catch(error => {
        console.log("Could not Auth: " + error);
        this.SET_TRANSACTION_SENDING(false);
        this.CHANGE_TOPUP_BALANCE_STATUS({
          provider: provider,
          status: false
        });
      });
  }
  // @Action
  // async getOrgs() {
  //   if (!this.orgsLoading) {
  //     this.SET_ORGS_LOADING(true);
  //     const myInit = {
  //       headers: {
  //         Authorization: `Bearer ${(await Auth.currentSession())
  //           .getIdToken()
  //           .getJwtToken()}`
  //       }
  //     };
  //     API.get("Akita", "/api/v1/organizations", myInit)
  //       .then(response => {
  //         console.log("success");
  //         console.log(response);
  //         this.SET_ORGS(response.data);
  //         //this.companies = response;
  //         this.SET_ORGS_LOADING(false);
  //       })
  //       .catch(error => {
  //         // console.log("error");
  //         console.log(error);
  //         this.SET_ORGS_LOADING(false);
  //       });
  //   }
  // }
}
